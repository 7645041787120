<template>
  <div class="home">
    <div class="bigTitle">用户购买协议</div>
    <!-- <img src="../../assets/images/privacyPolicyTitle.png" alt="" /> -->
    <div class="bigContent">发布日期：2021年11月27日</div>
    <div class="bigContent">生效日期：2020年10月27日</div>
    <div class="bigTitle">提示条款</div>
    <div class="bigContent">
      “{{ appName }}”购买协议（以下称“本协议”）由您主体{{
        companyName
      }}（以下称“公司”）签订；本平台按照本协议规定及不时发布的操作规则提供基于互联网的购买服务（以下称“购买服务”）。为更好地向用户提供购买服务，请服务使用人（以下称“用户”或“您”）开始使用购买服务之前认真阅读并充分理解本协议，其中，免除或者限制责任条款等重要内容将以加粗形式提示注意，请您重点阅读。如您自主选择按照页面提示完成购买程序，则视为您已同意并接受本协议项下的全部条款。如您不同意本协议，请您立即停止购买。
    </div>
    <div class="bigContent">
      本平台不鼓励未成年人使用购买服务，未成年人请委托监护人操作或在监护人明示同意下操作，否则请勿使用本购买服务。
    </div>
    <div class="bigContent">
      “咨询服务”购买后不能逆向兑换为人民币，且如无本协议约定的正当理由本平台亦不提供退还服务。
    </div>

    <div class="bigTitle">第一章 服务规则</div>
    <div class="bigContent">
      1、各购买方式对应的渠道商可能会按其标准制定相关购买渠道手续费用，并在用户购买时收取，请用户务必注意各购买方式的渠道商服务手续费，并按自身需求选取购买方式。
    </div>
    <div class="bigContent">
      2、在购买过程中，请您务必仔细确认自己的帐号并仔细选择相关操作选项。若因您自身输入帐号错误、操作不当或不了解、未充分了解购买计费方式等因素造成错选购买种类等情形而导致自身权益受损的，由此带来的损失由您自行承担，本平台不会作出任何补偿或赔偿。
    </div>
    <div class="bigContent">
      3、请您务必使用本平台指定的官方购买方式进行购买。若您使用非本平台所指定的购买方式或非法的方式进行购买，本平台不保证该购买顺利或者正确完成，同时可能引发其他风险，若因此造成您或任何第三方权益受损，由您自行应对处理并承担相应责任，本平台不会作出任何补偿或赔偿，同时本平台保留随时中止您的帐号部分或全部资格、冻结您的帐户余额、短期或永久封禁您的帐号及禁止您使用各项购买服务的权利。
    </div>
    <div class="bigContent">
      4、“咨询服务”购买属于本平台根据业务运营需要而提供的一次性服务，“咨询服务”并非存款、预付款或其他可无理由退还性质的商品或财产权利。用户购买成功后，本平台的购买服务即已提供完毕，本平台不再提供逆向兑换或转让服务，且无正当理由本平台亦不予提供退还服务（正当理由指本平台停止运营或停止向用户提供“咨询服务”及所有“咨询服务”相关功能、服务，或法律法规明确规定本平台应当退还），请您务必在具体操作前谨慎选择。
    </div>
    <div class="bigContent">
      5、如果本平台发现因系统故障或其他任何原因导致的处理错误，无论有利于本平台还是有利于您，本平台都有权在以电子邮件、短信或其他合理方式通知您后纠正该错误。如果该措施导致您实得不到相应的咨询服务，则本平台在确认该处理错误后会尽快将差额补足至您的帐户中。如果该错误导致您实际得到的咨询服务多出应得的服务收，则无论错误的性质和原因如何，本平台有权从您的帐户中直接扣除差额
    </div>
    <div class="bigContent">
      6、根据公司产品功能，您的购买帐号在公司运营的其他产品、平台（以下称“关联平台”）购买的虚拟币，可能在本平台与您购买的“咨询服务”合并计算并具备同等使用范围，此时，您在本平台使用关联平台虚拟币的行为同样受本协议约束；反之，您的咨询服务通过上述方式在关联平台使用的，您在关联平台使用“咨询服务”的行为，将同时受关联平台购买协议及相关规则条款约束。
    </div>
    <div class="bigTitle">第二章 权利声明</div>
    <div class="bigContent">
      1、您理解并同意，本平台有权基于交易安全等方面的考虑不时设定涉及交易的相关事项，包括但不限于交易限额、交易次数等。
    </div>
    <div class="bigContent">
      2、如购买过程中涉及由第三方提供的相关服务，则除遵守本协议约定外，您还需要同意并遵守该等第三方的协议及相关规则，在任何情况下，对于该第三方及其提供的相关服务而产生的争议由您自行与该第三方解决，本平台无需就此向您或该第三方承担任何责任。
    </div>
    <div class="bigContent">
      3、因您自身的原因导致本平台无法提供购买的咨询服务或提供购买的咨询服务时发生任何错误而产生的任何损失或责任，由您自行负责，本平台不承担责任，包括但不限于：
    </div>
    <div class="bigContent">（1）反对宪法确定的基本原则的；</div>
    <div class="bigContent">（2）因您的{{ appName }}账号失效、丢失、被封停；</div>
    <div class="bigContent">
      （3）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；
    </div>
    <div class="bigContent">（4）您将密码告知他人导致的财产损失；</div>
    <div class="bigContent">
      （5）因您个人的故意或重大过失所造成的财产损失。
    </div>
    <div class="bigContent">
      4、因下列状况导致您无法使用本平台的购买服务或使您的任何虚拟财产丢失时，您同意本平台无须承担损害赔偿责任，该状况包括但不限于：
    </div>
    <div class="bigContent">
      （1）在本平台公告之系统停机维护、升级、调整期间；
    </div>
    <div class="bigContent">
      （2）电信通讯或设备出现故障不能进行数据传输的；
    </div>
    <div class="bigContent">
      （3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成本平台系统障碍不能执行业务的；
    </div>
    <div class="bigContent">
      （4）由于黑客攻击、电信部门技术调整或故障、网站升级、相关第三方的问题等原因而造成的服务中断或者延迟。
    </div>
    <div class="bigContent">
      5、在任何情况下，本平台均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任。本平台对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用本平台提供的购买服务而支付的费用。
    </div>

    <div class="bigTitle">第三章 处罚规则</div>
    <div class="bigContent">
      1、如发生下列任何一种情形，本平台有权随时中断或终止向您提供本协议项下的网络服务（而无需另行单独通知您），且无需承担因购买服务中断或终止而给您或任何第三方造成损失的任何责任：
    </div>
    <div class="bigContent">（1）您提供的个人资料不真实；</div>
    <div class="bigContent">
      （2）您违反本协议、《用户服务协议》、《社区公约》或其他本平台对用户的管理规定；
    </div>
    <div class="bigContent">
      （3）您以营利、经营等非个人使用目的购买、使用“咨询服务”。
    </div>
    <div class="bigContent">
      2、用户在使用本平台提供的购买服务时，如出现任何的涉嫌犯罪、违法违规、违反社会主义道德风尚、违反《用户服务协议》、《社区公约》、本协议或其他本平台对用户的管理规定的情形，本平台有权视您行为的性质及严重程度决定对您的帐号采取暂时或永久封禁措施。帐号封禁后至解禁（如有）前，您帐户上剩余的咨询服务将被暂时冻结或全部扣除，不可继续用于购买本平台的产品及服务，同时不予返还您购买的咨询服务的现金价值。
    </div>

    <div class="bigTitle">第四章 附则</div>
    <div class="bigContent">
      1、为了向您提供更好的服务，或者基于法律规定、主管部门要求、业务开展情况等因素的更新与变化，本平台将适时对本协议内容进行修订，该等修订内容一经公布立即生效，本平台将通过本平台发出更新版本，您可通过咨询服务购买页面随时查阅最新版本的协议条款。若您在本协议内容发生修订后继续使用本平台提供的购买服务的，则视为您同意最新修订的协议内容；否则请您立即停止购买。
    </div>
    <!-- <div class="bigContent"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // appName: "仰和安心",
      // companyName: "深圳市仰和镜象技术有限公司",
      appName: "",
      companyName: "",
    };
  },
  created () {
    this.appName = this.$route.query.aName
    this.companyName = this.$route.query.cName
  }
};
</script>

<style lang="scss" scoped>
.home{
  box-sizing: border-box;
  padding: .3rem;
  background-color: #f7f7f7; 
  img{
    width: 1.28rem;
    height: .36rem;
  }
  .bigTitle{
    font-size: .28rem;
    color: #333;
    font-weight: bold;
    padding: .3rem 0 0;
  }
  .bigContent{
    font-size: .28rem;
      color: #4d4d4d;
      padding: .2rem 0 0;
      line-height: .4rem;
  }
}
</style>